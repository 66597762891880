import { WindowLocation } from "@reach/router";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import React, { PureComponent, ReactElement } from "react";

import Layout from "../components/layout";

class DummyHomePage extends PureComponent<{ location: WindowLocation }> {
  public componentDidMount(): void {
    navigate("/en/");
  }

  public render(): ReactElement {
    const { location } = this.props;
    return (
      <Layout location={location}>
        <div />
      </Layout>
    );
  }

  public static propTypes = {
    location: PropTypes.object.isRequired,
  };
}

export default DummyHomePage;
